import request from '@/utils/request';

const PATH = '/place';

export const getPlaceList = (type: string) => request({
  url: PATH,
  method: 'get',
  params: {
    type,
  },
});
