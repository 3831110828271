
import { getPlaceList } from '@/api/place';
import {
  getLat,
  getLng,
  setLat,
  setLng,
} from '@/utils/cookies';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
  },
})

export default class extends Vue {
  created() {
    this.initKakao();
  }

  mounted() {
    this.getPlaceList();
    this.initMap();
  }

  private makerList: any = [];

  private placeList = [];

  private loading = true;

  private type = 'TOURLIST';

  private typeList = [
    { label: '관광지', value: 'TOURLIST' },
    { label: '주차장', value: 'PARKING' },
    { label: '정류장', value: 'STOP' },
    { label: '화장실', value: 'TOILET' },
  ]

  private map = null;

  private overlayList: any = [];

  private initKakao() {
    if (!(window as any).kakao || (window as any).kakao.maps) {
      const script = document.createElement('script');
      script.src = `${window.location.protocol}//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.VUE_APP_KAKAO_CLIENT_ID}&libraries=services`;
      document.head.appendChild(script);
    }
  }

  /* eslint-disable */
  private initMap() {
    const container = document.getElementById('map');
    const option = {
      center: new (window as any).kakao.maps.LatLng('34.801194687587945', '127.94185477615481'),
      level: 8,
    };
    this.map = new (window as any).kakao.maps.Map(container, option);
  }

  private getPlaceList() {
    this.loading = true;
    this.overlayList.forEach((overlay: any) => overlay.setMap(null));
    this.overlayList = [];
    getPlaceList(this.type).then((res) => {
      this.loading = false;
      this.placeList = res.data;
      this.addMarkers(res.data);
      this.initMarker();
    });
  }

  private initMarker() {
    this.placeList.forEach((place: any) => {
      const markerPosition = new (window as any).kakao.maps.LatLng(place.lat, place.lng);

      // const imageSrc = 'http://t1.daumcdn.net/mapjsapi/images/marker.png';
      const imageSrc = '/img/map_marker.png';
      const imageSize = new (window as any).kakao.maps.Size(29, 42);

      // 마커의 이미지정보를 가지고 있는 마커이미지를 생성합니다
      const markerImage = new (window as any).kakao.maps.MarkerImage(imageSrc, imageSize);

      const marker = new (window as any).kakao.maps.Marker({
        position: markerPosition,
        image: markerImage,
      });
      marker.setMap(this.map);
      this.makerList.push(marker);

      // 커스텀 오버레이에 표출될 내용으로 HTML 문자열이나 document element가 가능합니다
      const content = `<div class="gis-item">${place.name}<br>${place.address}</div>`;
      // + `<div class="gis-item__price">55,000</div>` ;

      // 커스텀 오버레이를 생성합니다
      const customOverlay = new (window as any).kakao.maps.CustomOverlay({
        map: null,
        position: markerPosition,
        content,
        yAnchor: 1.9,
        visible: false,
      });

      (window as any).kakao.maps.event.addListener(marker, 'mouseover', () => {
        customOverlay.setMap(this.map);
      });

      (window as any).kakao.maps.event.addListener(marker, 'mouseout', () => {
        customOverlay.setMap(null);
      });

      (window as any).kakao.maps.event.addListener(marker, 'click', function() {
        (window as any).open(`https://map.kakao.com/link/map/${place.name},${place.lat},${place.lng}`);
      });
    });
  }

  private handleClickType(val: any) {
    if (this.type !== val) {
      this.type = val;
      this.getPlaceList();
    }
  }

  private addMarkers(placeList: any) {
    this.initMap();
    placeList.forEach((place: any) => {
      const type = place.type.toLowerCase();
      const markerContent = `
        <div class="overlay">
          <div class="overlay__inner overlay__inner--${type}"></div>
        </div>
      `;
      const position = new (window as any).kakao.maps.LatLng(place.lat, place.lng);
      const overlay = new (window as any).kakao.maps.CustomOverlay({
        position: position,
        content: markerContent,  
      });
      overlay.setMap(this.map);
      this.overlayList.push(overlay);
    });
  }
}
